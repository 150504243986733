<template>
  <div
    v-if="chat"
    class="messagesDashboard__chat js-chatBox"
  >
    <Stub/>
    <div class="messagesDashboard__chat-header js-chatBox-close" @click="closeChat">
      <span class="messagesDashboard__chat-header-icon">
        <SvgIcon name="left-arrow"/>
      </span>
      <span class="messagesDashboard__chat-header-text">{{chat.name}}</span>
    </div>
    <div class="messagesDashboard__chat-inner">
      <vue-scroll :ops="ops">
        <div class="messagesDashboard__chat-list js-chatBox-list js-customScroll">
          <div
            v-for="(item, index) of chat.chat"
            :key="index"
            class="messagesDashboard__chat-list-item"
          >
            <div
              class="messagesDashboard__chat-list-item-avatar"
              :style="{backgroundImage: `url(${item.ava})`}"
            ></div>
            <div class="messagesDashboard__chat-list-item-content">
              <div class="messagesDashboard__chat-list-item-name">{{item.firstName}}</div>
              <div
                class="messagesDashboard__chat-list-item-block"
                v-for="(message, index) of item.messages"
                :key="index"
              >
                <div class="messagesDashboard__chat-list-item-text">{{message.text}}</div>
                <div class="messagesDashboard__chat-list-item-time">{{message.time}}</div>
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
      <div class="messagesDashboard__chat-form">
        <form
          class="form form--sendMessage js-ajaxForm"
          data-href="/"
          data-action="sendMessage"
          id="sendMessageForm"
        >
          <label class="form__label form__label--text">
            <div class="form__field-wrapper">
              <textarea
                class="form__field"
                name="message"
                placeholder="Write a message..."
                autoComplete="off"
                required="required">
              </textarea>
            </div>
          </label>
          <div
            class="form__label form__label--button">
            <button class="form__button">
              <svg width="71" height="71" viewBox="0 0 71 71" fill="none">
                <circle cx="35.5" cy="35.5" r="35.5" fill="#ffffff"></circle>
                <path d="M22 41.0706L49 29.4992L22 17.9277V26.9277L41.2857 29.4992L22 32.0706V41.0706Z"></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Stub from '@/components/common/Stub/Stub'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['current', 'changeCurrent'],
    name: 'MessagesChat',
    data: () => ({
      status: false,
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      chat () {
        return this.$store.getters.getChat
      },
    },
    watch: {
      chat () {
        if (this.chat) {
          setTimeout(() => {
            const chatbox = document.querySelector('.js-chatBox')
            chatbox.classList.add('show')
            if (document.querySelector('html').classList.contains('d-mobile')) {
              document.querySelector('.content').classList.add('noScroll')
              document.querySelector('body').appendChild(chatbox)
            }
          }, 0)
        }
      },
    },
    updated () {
      console.log(this.current)
    },
    methods: {
      closeChat () {
        document.querySelector('.js-chatBox').classList.remove('show')
        document.querySelector('.content').classList.remove('noScroll')
        setTimeout(() => {
          this.$emit('changeCurrent', null)
          this.$store.commit('selectChat', null)
        }, 200)
      },
    },
    components: {
      Stub,
      SvgIcon,
    },
  }
</script>
