<template>
  <div class="messagesDashboard__list">
    <div
      v-for="item of list"
      :key="item.id"
      class="messagesDashboard__list-item js-message"
      :class="[{'open' : current === item.id}, {'disable': current !== item.id && current !== null}]"
    >
      <div
        class="messagesDashboard__list-item-avatar"
        :style="{backgroundImage: `url(${item.ava})`}"
      >
        <div v-if="newLetter === true" class="messagesDashboard__list-item-new">
          <SvgIcon name="mail-footer"/>
        </div>
      </div>
      <div class="messagesDashboard__list-item-header">
        <div class="messagesDashboard__list-item-name">{{item.name}}</div>
        <div class="messagesDashboard__list-item-descr">
          <div class="messagesDashboard__list-item-descr-item">Aviation</div>
          <div class="messagesDashboard__list-item-descr-item">FlyJets</div>
        </div>
        <div class="messagesDashboard__list-item-time">{{item.time}}</div>
      </div>
      <div class="messagesDashboard__list-item-inner">
        <div class="messagesDashboard__list-item-info">
          <ul>
            <li>
              <span class="messagesDashboard__list-item-info-title">RE:&ensp;</span>
              <span class="messagesDashboard__list-item-info-text messagesDashboard__list-item-info-text--subject">{{item.info.re}}</span>
            </li>
            <li>
              <span class="messagesDashboard__list-item-info-title">departure:&ensp;</span>
              <span class="messagesDashboard__list-item-info-text">{{item.info.departure}}</span>
            </li>
            <li>
              <span class="messagesDashboard__list-item-info-title">arrival:&ensp;</span>
              <span class="messagesDashboard__list-item-info-text">{{item.info.arrival}}</span>
            </li>
          </ul>
          <div class="messagesDashboard__list-item-info-date">{{item.date}}</div>
        </div>
        <div class="messagesDashboard__list-item-content">
          <span class="messagesDashboard__list-item-text">
            {{item.chat[item.chat.length-1].messages[
            item.chat[item.chat.length-1].messages.length-1
            ].text}}
          </span>
          <router-link to="#" class="messagesDashboard__list-item-link">read more</router-link>
        </div>
        <div class="messagesDashboard__list-item-icon js-chatBox-btn" @click="showMessageList(item.id)">
          <SvgIcon name="chat"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['list', 'newLetter', 'current', 'changeCurrent'],
    name: 'MessagesList',
    methods: {
      showMessageList (id) {
        this.$store.commit('selectChat', id)
        // this.current = id
        this.$emit('changeCurrent', id)
      },
      closeMenu (event) {
        if (!event.target.closest('.js-chatBox') && !event.target.closest('.js-chatBox-btn')) {
          const chatBox = document.querySelector('.js-chatBox')
          if (chatBox) {
            chatBox.classList.remove('show')
            if (document.querySelector('html').classList.contains('d-mobile')) {
              document.querySelector('.content').classList.remove('noScroll')
            }
          }
          setTimeout(() => {
            // this.current = null
            this.$emit('changeCurrent', null)
            this.$store.commit('selectChat', null)
          }, 200)
        }
      },
    },
    mounted () {
      document.addEventListener('click', this.closeMenu)
    },
    destroyed () {
      document.removeEventListener('click', this.closeMenu)
    },
    components: {
      SvgIcon,
    },
  }
</script>
