<template>
  <div class="messagesDashboard js-tabBox" :class="'dashboardType--' + dashboardType">
    <div class="messagesDashboard__header">
      <div class="messagesDashboard__title">Messages</div>
      <div class="messagesDashboard__nav">
        <div class="messagesDashboard__nav-item js-tabNavItem" @click="tabActivate">My network</div>
        <div class="messagesDashboard__nav-item js-tabNavItem" @click="tabActivate">All</div>
        <div class="messagesDashboard__nav-item js-tabNavItem" @click="tabActivate">New</div>
      </div>
    </div>
    <div class="messagesDashboard__content">
      <div class="messagesDashboard__content-inner js-customScroll">
        <vue-scroll :ops="ops">
          <div class="messagesDashboard__content-item js-tabContentItem">
            <MessagesNetwork :list="list" :current="current" @changeCurrent="changeCurrent"/>
          </div>
          <div class="messagesDashboard__content-item js-tabContentItem">
            <MessagesList :list="list" :newLetter="false" :current="current" @changeCurrent="changeCurrent"/>
          </div>
          <div class="messagesDashboard__content-item js-tabContentItem">
            <MessagesList :list="list" :newLetter="true" :current="current" @changeCurrent="changeCurrent"/>
          </div>
        </vue-scroll>
      </div>
      <MessagesChat :current="current" @changeCurrent="changeCurrent"/>
    </div>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import MessagesNetwork from '@/components/dashboard/Messages/MessagesNetwork'
  import MessagesList from '@/components/dashboard/Messages/MessagesList'
  import MessagesChat from '@/components/dashboard/Messages/MessagesChat'
  import './Messages.styl'

  export default {
    props: ['dashboardType'],
    name: 'Messages',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      current: null,
    }),
    computed: {
      list () {
        return this.$store.state.messages.list
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
        document.querySelectorAll('.js-acc-content').forEach((content) => {
          this.$root.animations.slideUp(content)
        })
      },
      changeCurrent (id) {
        this.current = id
      },
    },
    components: {
      MessagesNetwork,
      MessagesList,
      MessagesChat,
    },
  }
</script>
