<template>
  <div class="messagesDashboard__network">
    <div
      v-for="item of list"
      :key="item.id"
      class="messagesDashboard__network-item js-message"
      :class="[
        {'online' : item.online},
        {'open' : current === item.id},
        {'disable': current !== item.id && current !== null}
      ]"
    >
      <div
        class="messagesDashboard__network-item-avatar"
        :style="{backgroundImage: `url(${item.ava})`}"
      ></div>
      <div class="messagesDashboard__network-item-name">{{item.name}}</div>
      <div class="messagesDashboard__network-item-link js-chatBox-btn" @click="showMessageList(item.id)">
        <div class="messagesDashboard__network-item-link-text">Write message</div>
        <div
          v-if="item.dialog === true"
          class="messagesDashboard__network-item-link-icon">
          <SvgIcon name="chat"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['list', 'current', 'changeCurrent'],
    name: 'MessagesNetwork',
    methods: {
      showMessageList (id) {
        this.$store.commit('selectChat', id)
        this.$emit('changeCurrent', id)
      },
      closeMenu (event) {
        if (!event.target.closest('.js-chatBox') && !event.target.closest('.js-chatBox-btn')) {
          const chatBox = document.querySelector('.js-chatBox')
          if (chatBox) {
            chatBox.classList.remove('show')
          }
          setTimeout(() => {
            this.$emit('changeCurrent', null)
            this.$store.commit('selectChat', null)
          }, 200)
        }
      },
    },
    mounted () {
      document.addEventListener('click', this.closeMenu)
    },
    destroyed () {
      document.removeEventListener('click', this.closeMenu)
    },
    components: {
      SvgIcon,
    },
  }
</script>
